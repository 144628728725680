/* eslint-disable no-useless-computed-key */
import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// import innovationThinking from "./../../assets/images/report/pp-b-5.svg";
// import innovationBias from "./../../assets/images/report/pp-b-6.svg";
// import innovationDemand from "./../../assets/images/report/pp-b-7.svg";
// import entreplastixMind from "./../../assets/images/report/pp-b-8.svg";
// import thumbsUp from "./../../assets/images/report/thumbsup.svg";
// import improvement from "./../../assets/images/report/improvement.svg";
// import notApplicable from "./../../assets/images/report/not-applicable.svg";

import AnimatedDrawer from "../common/AnimationDrawer";
import tipArrowIcon from "./../../assets/images/report/tip-arrow-icon.svg";
import etqCube from "./../../assets/images/report/score/etq-cube.svg";

const useStyles = makeStyles((theme) => ({
  reportContentContainer: {
    height: "calc(100vh - 120px)",
    overflow: "hidden",
    flexGrow: 1,
    textAlign: "left",
    backgroundColor: "#fff",
    position: "relative",
    padding: "0 140px",
    ["@media (max-width:1050px)"]: {
      padding: "0 104px",
    },
    ["@media (min-width:1052px) and (max-width:1282px)"]: {
      padding: "0 108px",
    },
    ["@media (min-width:1283px) and (max-width:1442px)"]: {
      padding: "0 104px",
    },
    ["@media (min-width:1443px) and (max-width:1512px)"]: {
      padding: "0 104px",
    },
  },
  rightSideContentContainer: {
    flex: 1,
    background: "none",
    boxShadow: "none",
    position: "relative",
    textAlign: "center",
  },
  rightSideContainer: {
    marginTop: "100px",
    "@media (max-width:1050px)": {
      marginTop: "0",
    },
    "@media (min-width:1052px) and (max-width:1282px)": {
      marginTop: "16px",
    },
    "@media (min-width:1283px) and (max-width:1442px)": {
      marginTop: "24px",
    },
    "@media (min-width:1443px) and (max-width:1512px)": {},
    ["@media (min-width:1443px)"]: {
      margin: "0",
    },
  },
  sectionTitle: {
    color: "#214697",
    font: "normal normal 500 36px/42px PoppinsSemiBold",
    margin: "36px 0",
    ["@media (max-width:1282px)"]: {
      font: "normal normal 500 30px/36px PoppinsSemiBold",
      margin: "24px 0 12px",
    },
    ["@media (min-width:1283px) and (max-width:1366px)"]: {
      font: "normal normal 500 26px/30px PoppinsSemiBold",
    },
    ["@media (min-width:1367px) and (max-width:1582px)"]: {
      font: "normal normal 500 36px/42px PoppinsSemiBold",
    },
    ["@media (min-width:1443px)"]: {
      font: "normal normal 500 36px/42px PoppinsSemiBold",
    },
  },
  sectionSubTitle: {
    color: "#000",
    font: "normal normal 500 14px/16px PoppinsSemiBold",
    textTransform: "uppercase",
    cursor: "pointer",
    ["@media (max-width:1025px)"]: {
      font: "normal normal 500 12px/14px PoppinsSemiBold",
    },
    ["@media (min-width:1026px) and (max-width:1282px)"]: {
      font: "normal normal 500 14px/16px PoppinsSemiBold",
    },
    ["@media (min-width:1383px) and (max-width:1582px)"]: {
      font: "normal normal 500 16px/18px PoppinsSemiBold",
    },
    ["@media (min-width:1443px)"]: {
      font: "normal normal 500 30px/34px PoppinsSemiBold",
      marginBottom: "20px",
    },
  },
  sectionSubTitleBorder: {
    borderBottom: "1px solid #B7AEAE",
    paddingBottom: "16px",
    marginBottom: 4,
  },
  sectionSubTitleBorder2: {
    borderTop: "1px solid #B7AEAE",
  },
  titleBorderTop: {
    borderTop: "1px solid #B7AEAE",
    marginTop: "3px",
    paddingTop: "25px",
  },
  sectionBottomBorder: {
    borderBottom: "1px solid #BE2431",
  },
  paragraph: {
    font: "normal normal 400 18px/24px PoppinsMedium",
    color: "#626262",
    padding: "0 14px 0 0px",
    ["@media (max-width:1025px)"]: {
      font: "normal normal 400 12px/18px PoppinsMedium",
    },
    ["@media (min-width:1026px) and (max-width:1282px)"]: {
      font: "normal normal 400 14px/22px PoppinsMedium",
    },
    ["@media (min-width:1283px) and (max-width:1366px)"]: {
      font: "normal normal 400 16px/22px PoppinsMedium",
    },
    ["@media (min-width:1367px) and (max-width:1442px)"]: {
      font: "normal normal 400 20px/26px PoppinsMedium",
    },
    ["@media (min-width:1443px)"]: {
      font: "normal normal 400 21px/30px PoppinsMedium",
    },
  },
  captionText: {
    margin: "5px 0 16px",
    font: "normal normal 400 14px/16px PoppinsMedium",
    paddingRight: 0,
    ["@media (max-width:1025px)"]: {
      font: "normal normal 400 12px/14px PoppinsMedium",
    },
    ["@media (min-width:1026px) and (max-width:1282px)"]: {
      font: "normal normal 400 14px/16px PoppinsMedium",
    },
    ["@media (min-width:1283px) and (max-width:1366px)"]: {
      font: "normal normal 400 16px/18px PoppinsMedium",
    },
    ["@media (min-width:1367px) and (max-width:1442px)"]: {
      font: "normal normal 400 20px/22px PoppinsMedium",
    },
    ["@media (min-width:1443px)"]: {
      font: "normal normal 400 24px/28px PoppinsMedium",
      marginBottom: "30px",
    },
  },
  verticalHorizontalCenter: {
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
  },
  alignFlexEnd: {
    display: "flex",
    alignItems: "flex-end",
  },
  tableCaption: {
    color: "#214697",
    marginBottom: 0,
    font: "normal normal 500 12px/20px PoppinsSemiBold",
    ["@media (min-width:1283px) and (max-width:1366px)"]: {
      font: "normal normal 500 14px/20px PoppinsSemiBold",
    },
    ["@media (min-width:1367px) and (max-width:1442px)"]: {
      font: "normal normal 500 16px/22px PoppinsSemiBold",
    },
    ["@media (min-width:1443px)"]: {
      font: "normal normal 500 18px/24px PoppinsSemiBold",
    },
  },
  thumbnailImg: {
    width: "26px",
    height: "26px",
  },
  thumbnailText: {
    font: "normal normal 500 10px/14px PoppinsMedium",
    color: "#626262",
    padding: "0 14px 0 0px",
    marginLeft: "10px",
    textAlign: "left",
    ["@media (max-width:1282px)"]: {
      font: "normal normal 500 10px/14px PoppinsMedium",
    },
    ["@media (min-width:1283px) and (max-width:1366px)"]: {
      font: "normal normal 400 10px/14px PoppinsMedium",
    },
    ["@media (min-width:1367px) and (max-width:1442px)"]: {
      font: "normal normal 500 12px/16px PoppinsMedium",
      padding: "0 14px 0 0px",
    },
    ["@media (min-width:1443px)"]: {
      font: "normal normal 400 14px/20px PoppinsMedium",
    },
  },

  tipContainer: {
    position: "absolute",
    top: "0",
    right: "25px",
    color: "#6FBC44",
    width: "100px",
    height: "auto",
    font: "normal normal 500 12px/16px PoppinsSemiBold",
    zIndex: 999,
    ["@media (max-width:1282px)"]: {},
    ["@media (min-width:1283px) and (max-width:1366px)"]: {
      width: "100px",
      font: "normal normal 500 14px/18px PoppinsSemiBold",
    },
    ["@media (min-width:1367px) and (max-width:1442px)"]: {
      font: "normal normal 500 16px/22px PoppinsSemiBold",
      top: "10px",
      width: "125px",
    },
    ["@media (min-width:1443px)"]: {
      font: "normal normal 500 16px/22px PoppinsSemiBold",
      top: "30px",
      width: "125px",
      right: "50px",
    },
  },
  tipArrowIcon: {
    ["@media (min-width:1443px)"]: {
      width: "50px",
    },
  },

  keyContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
  },
}));

export default function Screen6(props) {
  const classes = useStyles();

  const [ppNumber, setPpNumber] = useState(0);
  const [ppType, setPpType] = useState(0);

  const openSelectedOutcomeDescription = (type, ppNumber) => (event) => {
    document.getElementsByClassName(
      "animated-drawer-container"
    )[0].style.display = "block";
    document.getElementById("header").style.position = "static";
    document.getElementById("tipContainer").style.display = "none";
    document.getElementById("progressBar").style.display = "none";
    setPpNumber(ppNumber);
    setPpType(type);
  };

  const closeSelectedOutcomeDescription = (type) => {
    document.getElementsByClassName(
      "animated-drawer-container"
    )[0].style.display = "none";
    document.getElementById("header").style.position = "relative";
    document.getElementById("tipContainer").style.display = "block";
    document.getElementById("progressBar").style.display = "block";
  };

  const getIconTitle = (data) => {
    if (data === "improvement") {
      return "Scope for Improvement";
    } else if (data === "thumbsup") {
      return "Well Done";
    } else {
      return "Biases & Motivational factors were not tested here";
    }
  };

  const getCaptionColor = (data) => {
    console.log("data ======>", data);
    if (data === "Scope for Improvement") {
      return "red";
    } else if (data === "Well done") {
      return "green";
    } else {
      return "blue";
    }
  };

  return (
    <div className="pos-rel">
      <div id="tipContainer" className={classes.tipContainer}>
        <p className="greenColor">Tip: click on each icon to learn more</p>
        <img
          className={classes.tipArrowIcon}
          src={tipArrowIcon}
          alt="tipArrowIcon"
        />
      </div>
      <Grid
        container
        component="main"
        className={`${classes.reportContentContainer} ${classes.verticalHorizontalCenter}`}
      >
        <Grid item xs={12} sm={4} md={4}>
          <h3 className={classes.sectionTitle}>Innovation Thinking</h3>
          <p className={classes.paragraph}>
            {props.data.category_id === 1 ? (
              <React.Fragment>
                {" "}
                Innovation Thinking is one’s mindset for creating innovation,
                demand creation and ideation relevant in a start-up and self-
                employment scenario.
              </React.Fragment>
            ) : props.data.category_id === 4 ? (
              <React.Fragment>
                Innovation Thinking is one’s mindset about innovation, demand
                creation, along with your enterprising approach relevant for an
                employability in a commercial career.{" "}
              </React.Fragment>
            ) : (
              <React.Fragment>
                Innovation Thinking is one’s mindset for creating innovation,
                demand creation, along with your enterprising approach relevant
                in a commercial role.
              </React.Fragment>
            )}
          </p>
          <p className={classes.paragraph}>
            {props.data.category_id === 1 ? (
              <React.Fragment>
                Entrepreneurial potential is strongly influenced by one's
                biases, motivational factors and consistency in thinking. This
                table demonstrates yours for creating innovation, demand
                creation and also your enterprising approach.
              </React.Fragment>
            ) : props.data.category_id === 4 ? (
              <React.Fragment>
                Entrepreneurial potential is strongly influenced by one's
                biases, motivational factors and consistency in thinking. This
                table demonstrates yours for creating innovation, demand
                creation and also your enterprising approach.
              </React.Fragment>
            ) : (
              <React.Fragment>
                Entrepreneurial potential is strongly influenced by one's
                biases, motivational factors and consistency in thinking. This
                table demonstrates yours for creating innovation, demand
                creation and also your enterprising approach.
              </React.Fragment>
            )}
          </p>

          {/* {props.data.category_id === 1 ? (
            <React.Fragment>
              <p className={classes.paragraph}>
                Innovation Thinking is one’s mindset for creating innovation,
                demand creation and ideation relevant in a start-up and self-
                employment scenario.
              </p>
              <p className={classes.paragraph}>
                Entrepreneurial potential is strongly influenced by one's
                biases, motivational factors and consistency in thinking. This
                table demonstrates yours for creating innovation, demand
                creation and also your enterprising approach.
              </p>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <p className={classes.paragraph}>
                Innovation Thinking is one’s mindset for creating innovation,
                demand creation, along with your enterprising approach relevant
                in a commercial role.
              </p>
              <p className={classes.paragraph}>
                Entrepreneurial potential is strongly influenced by one's
                biases, motivational factors and consistency in thinking. This
                table demonstrates yours for creating innovation, demand
                creation and also your enterprising approach.
              </p>
            </React.Fragment>
          )} */}
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          md={8}
          component={Paper}
          elevation={9}
          className={classes.rightSideContentContainer}
        >
          <Grid container className={classes.rightSideContainer}>
            <Grid item xs={1} sm={1} md={1}></Grid>
            <Grid item xs={5} sm={5} md={5}>
              {/* <div> */}
              <h4
                className={`${classes.sectionSubTitle}`}
                onClick={openSelectedOutcomeDescription("it", 5)}
              >
                {props.data.category_id === 1 ? (
                  <span>
                    Flexibility <br /> & Depth
                  </span>
                ) : (
                  <span>
                    Flexibility <br /> & Depth
                  </span>
                )}
              </h4>
              <img
                src={
                  require(`./../../assets/images/report/score/${props.data.is1MatrixCaption}.svg`)
                    .default
                }
                alt=""
                class="ht-icon"
                onClick={openSelectedOutcomeDescription("it", 5)}
                title="Learn More"
              />
              <p
                className={`${classes.captionText} ${getCaptionColor(
                  props.data.is1MatrixCaption
                )}`}
              >
                {props.data.is1MatrixCaption}
              </p>
              {/* </div> */}
            </Grid>
            <Grid item xs={1} sm={1} md={1} style={{ position: "relative" }}>
              <div className="verticalLine"></div>
              <div className="verticalLine2"></div>
            </Grid>
            <Grid item xs={5} sm={5} md={5}>
              <div>
                <h4
                  className={`${classes.sectionSubTitle}`}
                  onClick={openSelectedOutcomeDescription("it", 7)}
                >
                  {props.data.category_id === 1 ? (
                    <span>
                      Demand <br /> creation
                    </span>
                  ) : (
                    <span>
                      Demand <br /> creation
                    </span>
                  )}
                </h4>
                <img
                  // src={improvement}
                  src={
                    require(`./../../assets/images/report/score/${props.data.is3MatrixCaption}.svg`)
                      .default
                  }
                  alt=""
                  class="ht-icon"
                  onClick={openSelectedOutcomeDescription("it", 7)}
                  title="Learn More"
                />
                <p
                  className={`${classes.captionText} ${getCaptionColor(
                    props.data.is3MatrixCaption
                  )}`}
                >
                  {props.data.is3MatrixCaption}
                </p>
              </div>
            </Grid>
            <Grid item xs={1} sm={1} md={1}></Grid>
            <Grid item xs={5} sm={5} md={5}>
              <div className={`${classes.sectionSubTitleBorder2}`}>
                <h4
                  className={`${classes.sectionSubTitle} ${classes.titleBorderTop}`}
                  onClick={openSelectedOutcomeDescription("it", 6)}
                >
                  {props.data.category_id === 1 ? (
                    <span>
                      Innovation <br /> Biases
                    </span>
                  ) : (
                    <span>
                      Commercialisation <br /> approach
                    </span>
                  )}
                </h4>
                <img
                  // src={practice}
                  src={
                    require(`./../../assets/images/report/score/${props.data.is2MatrixCaption}.svg`)
                      .default
                  }
                  alt=""
                  class="ht-icon"
                  onClick={openSelectedOutcomeDescription("it", 6)}
                  title="Learn More"
                />
                <p
                  className={`${classes.captionText} ${getCaptionColor(
                    props.data.is2MatrixCaption
                  )}`}
                >
                  {props.data.is2MatrixCaption}
                </p>
              </div>
            </Grid>
            <Grid item xs={1} sm={1} md={1} style={{ position: "relative" }}>
              <img src={etqCube} alt="" class="ht-icon etq-cube" />

              <div className="verticalLine bt-0"></div>
              <div className="verticalLine2 bt-0"></div>
            </Grid>
            <Grid item xs={5} sm={5} md={5}>
              <div className={`${classes.sectionSubTitleBorder2}`}>
                <h4
                  className={`${classes.sectionSubTitle}  ${classes.titleBorderTop}`}
                  onClick={openSelectedOutcomeDescription("it", 8)}
                >
                  {props.data.category_id === 1 ? (
                    <span>
                      Entrepreneurial <br /> Mind
                    </span>
                  ) : (
                    <span>
                      Enterprising <br /> approach
                    </span>
                  )}
                </h4>
                <img
                  // src={welldone}
                  src={
                    require(`./../../assets/images/report/score/${props.data.is4MatrixCaption}.svg`)
                      .default
                  }
                  alt=""
                  class="ht-icon"
                  onClick={openSelectedOutcomeDescription("it", 8)}
                  title="Learn More"
                />
                <p
                  className={`${classes.captionText} ${getCaptionColor(
                    props.data.is4MatrixCaption
                  )}`}
                >
                  {props.data.is4MatrixCaption}
                </p>
              </div>
            </Grid>
            <Grid item xs={2} sm={2} md={2}></Grid>
            <Grid item xs={10} sm={10} md={10}>
              <p
                className={classes.paragraph}
                style={{
                  color: "#000",
                  fontFamily: "PoppinsMedium",
                  fontWeight: "600",
                  textAlign: "left",
                }}
              >
                Your biases, motivational factors and mindset were evaluated.
              </p>
            </Grid>
          </Grid>
          {/* <div>
            <Grid container className={classes.rightSideContainer}>
              <Grid item xs={1}></Grid>
              <Grid item xs className={classes.sectionBottomBorder}>
                <img
                  src={innovationThinking}
                  alt=""
                  class="ht-icon"
                  onClick={openSelectedOutcomeDescription("it", 5)}
                  title="Learn More"
                />
                <h4
                  className={`${classes.sectionSubTitle} ${classes.sectionSubTitleBorder}`}
                >
                  {props.data.category_id === 1 ? (
                    <span>
                      Flexibility &amp; <br /> Depth
                    </span>
                  ) : (
                    <span>
                      Flexibility &amp; <br /> Depth
                    </span>
                  )}
                </h4>
              </Grid>
              <Grid item xs className={classes.sectionBottomBorder}>
                <img
                  src={innovationBias}
                  alt=""
                  class="ht-icon"
                  onClick={openSelectedOutcomeDescription("it", 6)}
                  title="Learn More"
                />
                <h4
                  className={`${classes.sectionSubTitle} ${classes.sectionSubTitleBorder}`}
                >
                  {props.data.category_id === 1 ? (
                    <span>
                      Innovation <br /> Biases
                    </span>
                  ) : (
                    <span>
                      Commercialisation <br /> approach
                    </span>
                  )}{" "}
                  <br />
                </h4>
              </Grid>
              <Grid item xs className={classes.sectionBottomBorder}>
                <img
                  src={innovationDemand}
                  alt=""
                  class="ht-icon"
                  onClick={openSelectedOutcomeDescription("it", 7)}
                  title="Learn More"
                />
                <h4
                  className={`${classes.sectionSubTitle} ${classes.sectionSubTitleBorder}`}
                >
                  {props.data.category_id === 1 ? (
                    <span>
                      Demand <br /> Creation
                    </span>
                  ) : (
                    <span>
                      Demand <br /> Creation
                    </span>
                  )}{" "}
                </h4>
              </Grid>
              <Grid item xs className={classes.sectionBottomBorder}>
                <img
                  src={entreplastixMind}
                  alt=""
                  class="ht-icon"
                  onClick={openSelectedOutcomeDescription("it", 8)}
                  title="Learn More"
                />
                <h4
                  className={`${classes.sectionSubTitle} ${classes.sectionSubTitleBorder}`}
                >
                  {props.data.category_id === 1 ? (
                    <span>
                      Entrepreneurial <br /> Mind
                    </span>
                  ) : (
                    <span>
                      Enterprising <br /> approach
                    </span>
                  )}{" "}
                </h4>
              </Grid>
            </Grid>
          </div>

          <div>
            <Grid container style={{ borderBottom: "1px solid #214697" }}>
              <Grid item xs={1} className={classes.alignFlexEnd}>
                <p className={classes.tableCaption}>MINDSET</p>
              </Grid>
              <Grid item xs>
                <img
                  src={
                    require(`./../../assets/images/report/${props.data.innovationThinkingMindsetInnovationThinking}.svg`)
                      .default
                  }
                  alt=""
                  className={
                    "icon-" +
                    props.data.innovationThinkingMindsetInnovationThinking
                  }
                  title={getIconTitle(
                    props.data.innovationThinkingMindsetInnovationThinking
                  )}
                />
              </Grid>
              <Grid item xs>
                <img
                  src={
                    require(`./../../assets/images/report/${props.data.innovationThinkingMindsetInnovationBias}.svg`)
                      .default
                  }
                  alt=""
                  className={
                    "icon-" + props.data.innovationThinkingMindsetInnovationBias
                  }
                  title={getIconTitle(
                    props.data.innovationThinkingMindsetInnovationBias
                  )}
                />
              </Grid>
              <Grid item xs>
                <img
                  src={
                    require(`./../../assets/images/report/${props.data.innovationThinkingMindsetInnovationDemand}.svg`)
                      .default
                  }
                  alt=""
                  className={
                    "icon-" +
                    props.data.innovationThinkingMindsetInnovationDemand
                  }
                  title={getIconTitle(
                    props.data.innovationThinkingMindsetInnovationDemand
                  )}
                />
              </Grid>
              <Grid item xs>
                <img
                  src={
                    require(`./../../assets/images/report/${props.data.innovationThinkingMindsetEntrepreneurialMind}.svg`)
                      .default
                  }
                  alt=""
                  className={
                    "icon-" +
                    props.data.innovationThinkingMindsetEntrepreneurialMind
                  }
                  title={getIconTitle(
                    props.data.innovationThinkingMindsetEntrepreneurialMind
                  )}
                />
              </Grid>
            </Grid>
          </div>

          <div>
            <Grid container style={{ borderBottom: "1px solid #214697" }}>
              <Grid item xs={1} className={classes.alignFlexEnd}>
                <p className={classes.tableCaption}>BIASES</p>
              </Grid>
              <Grid item xs>
                <img
                  src={
                    require(`./../../assets/images/report/${props.data.innovationThinkingBiasesInnovationThinking}.svg`)
                      .default
                  }
                  alt=""
                  className={
                    "icon-" +
                    props.data.innovationThinkingBiasesInnovationThinking
                  }
                  title={getIconTitle(
                    props.data.innovationThinkingBiasesInnovationThinking
                  )}
                />
              </Grid>
              <Grid item xs>
                <img
                  src={
                    require(`./../../assets/images/report/${props.data.innovationThinkingBiasesInnovationBias}.svg`)
                      .default
                  }
                  alt=""
                  className={
                    "icon-" + props.data.innovationThinkingBiasesInnovationBias
                  }
                  title={getIconTitle(
                    props.data.innovationThinkingBiasesInnovationBias
                  )}
                />
              </Grid>
              <Grid item xs>
                <img
                  src={
                    require(`./../../assets/images/report/not-applicable.svg`)
                      .default
                  }
                  alt=""
                  className={"icon-notApplicable"}
                  title={getIconTitle("notApplicable")}
                />
              </Grid>
              <Grid item xs>
                <img
                  src={
                    require(`./../../assets/images/report/not-applicable.svg`)
                      .default
                  }
                  alt=""
                  className={"icon-notApplicable"}
                  title={getIconTitle("notApplicable")}
                />
              </Grid>
            </Grid>
          </div>

          <div>
            <Grid container style={{ borderBottom: "1px solid #214697" }}>
              <Grid item xs={1} className={classes.alignFlexEnd}>
                <p className={classes.tableCaption}>MOTIVATION</p>
              </Grid>
              <Grid item xs>
                <img
                  src={
                    require(`./../../assets/images/report/${props.data.innovationThinkingMotivationInnovationThinking}.svg`)
                      .default
                  }
                  alt=""
                  className={
                    "icon-" +
                    props.data.innovationThinkingMotivationInnovationThinking
                  }
                  title={getIconTitle(
                    props.data.innovationThinkingMotivationInnovationThinking
                  )}
                />
              </Grid>
              <Grid item xs>
                <img
                  src={
                    require(`./../../assets/images/report/${props.data.innovationThinkingMotivationInnovationBias}.svg`)
                      .default
                  }
                  alt=""
                  className={
                    "icon-" +
                    props.data.innovationThinkingMotivationInnovationBias
                  }
                  title={getIconTitle(
                    props.data.innovationThinkingMotivationInnovationBias
                  )}
                />
              </Grid>
              <Grid item xs>
                <img
                  src={
                    require(`./../../assets/images/report/${props.data.innovationThinkingMotivationInnovationDemand}.svg`)
                      .default
                  }
                  alt=""
                  className={
                    "icon-" +
                    props.data.innovationThinkingMotivationInnovationDemand
                  }
                  title={getIconTitle(
                    props.data.innovationThinkingMotivationInnovationDemand
                  )}
                />
              </Grid>
              <Grid item xs>
                <img
                  src={
                    require(`./../../assets/images/report/${props.data.innovationThinkingMotivationEntrepreneurialMind}.svg`)
                      .default
                  }
                  alt=""
                  className={
                    "icon-" +
                    props.data.innovationThinkingMotivationEntrepreneurialMind
                  }
                  title={getIconTitle(
                    props.data.innovationThinkingMotivationEntrepreneurialMind
                  )}
                />
              </Grid>
            </Grid>
          </div>

          <div>
            <Grid container>
              <Grid item xs={1}>
                <p style={{ color: "rgb(190, 36, 49)" }}></p>
              </Grid>
              <Grid item xs className={classes.keyContainer}>
                <p className={classes.thumbnailText} style={{ float: "right" }}>
                  KEY:
                </p>
              </Grid>
              <Grid item xs className={classes.verticalHorizontalCenter}>
                <img
                  className={classes.thumbnailImg}
                  src={notApplicable}
                  alt=""
                />
                <p className={classes.thumbnailText}>
                  Biases & Motivational <br /> factors were not tested here
                </p>
              </Grid>
              <Grid item xs className={classes.verticalHorizontalCenter}>
                <img
                  className={classes.thumbnailImg}
                  src={improvement}
                  alt=""
                />
                <p className={classes.thumbnailText}>
                  Scope <br /> for Improvement
                </p>
              </Grid>
              <Grid item xs className={classes.verticalHorizontalCenter}>
                <img className={classes.thumbnailImg} src={thumbsUp} alt="" />
                <p className={classes.thumbnailText}>
                  Well <br /> Done
                </p>
              </Grid>
            </Grid>
          </div> */}
        </Grid>

        <div class="animated-drawer-container">
          {props.data.pointsToPonder &&
            props.data.pointsToPonder.map((score) => {
              return ppNumber === score.id ? (
                <AnimatedDrawer
                  animatedDrawerType={closeSelectedOutcomeDescription}
                  ppType={ppNumber}
                  data={score}
                  type={ppType}
                ></AnimatedDrawer>
              ) : null;
            })}
        </div>
      </Grid>
    </div>
  );
}
