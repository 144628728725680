/* eslint-disable no-useless-computed-key */
import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import trust from "./../../assets/images/report/pp-b-1.svg";
import smartNetworks from "./../../assets/images/report/pp-b-2.svg";
import workMeaning from "./../../assets/images/report/pp-b-3.svg";
import socialBehaviour from "./../../assets/images/report/pp-b-4.svg";
// import welldone from "./../../assets/images/report/score/welldone-new.svg";
// import improvement from "./../../assets/images/report/score/improvement-new.svg";
// import practice from "./../../assets/images/report/score/practice-new.svg";
import etqCube from "./../../assets/images/report/score/etq-cube.svg";
import AnimatedDrawer from "../common/AnimationDrawer";
import tipArrowIcon from "./../../assets/images/report/tip-arrow-icon.svg";

const useStyles = makeStyles((theme) => ({
  reportContentContainer: {
    height: "calc(100vh - 120px)",
    overflow: "hidden",
    flexGrow: 1,
    textAlign: "left",
    backgroundColor: "#fff",
    position: "relative",
    padding: "0 140px",
    ["@media (max-width:1050px)"]: {
      padding: "0 104px",
    },
    ["@media (min-width:1052px) and (max-width:1282px)"]: {
      padding: "0 108px",
    },
    ["@media (min-width:1283px) and (max-width:1442px)"]: {
      padding: "0 104px",
    },
    ["@media (min-width:1443px) and (max-width:1512px)"]: {
      padding: "0 104px",
    },
  },
  paper: {
    color: "#FFFFFF",
    background: "#1D1D1B 0% 0% no-repeat padding-box",
    padding: "5rem 4rem",
    textAlign: "left",
  },
  rightSideContentContainer: {
    flex: 1,
    background: "none",
    boxShadow: "none",
    position: "relative",
    textAlign: "center",
  },
  rightSideContainer: {
    marginTop: "0px",
    "@media (max-width:1050px)": {
      marginTop: "0",
    },
    "@media (min-width:1052px) and (max-width:1282px)": {
      marginTop: "0px",
    },
    "@media (min-width:1283px) and (max-width:1442px)": {
      marginTop: "0px",
    },
    ["@media (min-width:1443px)"]: {
      margin: "0",
    },
  },
  sectionTitle: {
    color: "#BE2431",
    font: "normal normal 500 36px/42px PoppinsSemiBold",
    margin: "36px 0 18px",
    ["@media (max-width:1282px)"]: {
      font: "normal normal 500 30px/36px PoppinsSemiBold",
      margin: "24px 0 12px",
    },
    ["@media (min-width:1283px) and (max-width:1366px)"]: {
      font: "normal normal 500 26px/30px PoppinsSemiBold",
    },
    ["@media (min-width:1367px) and (max-width:1582px)"]: {
      font: "normal normal 500 36px/42px PoppinsSemiBold",
    },
    ["@media (min-width:1443px)"]: {
      font: "normal normal 500 36px/42px PoppinsSemiBold",
    },
  },
  sectionSubTitle: {
    color: "#000",
    font: "normal normal 500 14px/16px PoppinsSemiBold",
    textTransform: "uppercase",
    cursor: "pointer",
    ["@media (max-width:1025px)"]: {
      font: "normal normal 500 12px/14px PoppinsSemiBold",
    },
    ["@media (min-width:1026px) and (max-width:1282px)"]: {
      font: "normal normal 500 14px/16px PoppinsSemiBold",
    },
    ["@media (min-width:1383px) and (max-width:1582px)"]: {
      font: "normal normal 500 16px/18px PoppinsSemiBold",
    },
    ["@media (min-width:1443px)"]: {
      font: "normal normal 500 30px/34px PoppinsSemiBold",
      marginBottom: "20px",
    },
  },
  paragraph: {
    font: "normal normal 400 18px/24px PoppinsMedium",
    color: "#626262",
    padding: "0 14px 0 0px",
    ["@media (max-width:1025px)"]: {
      font: "normal normal 400 12px/18px PoppinsMedium",
    },
    ["@media (min-width:1026px) and (max-width:1282px)"]: {
      font: "normal normal 400 14px/22px PoppinsMedium",
    },
    ["@media (min-width:1283px) and (max-width:1366px)"]: {
      font: "normal normal 400 16px/22px PoppinsMedium",
    },
    ["@media (min-width:1367px) and (max-width:1442px)"]: {
      font: "normal normal 400 20px/26px PoppinsMedium",
    },
    ["@media (min-width:1443px)"]: {
      font: "normal normal 400 21px/30px PoppinsMedium",
    },
  },

  captionText: {
    margin: "5px 0 16px",
    font: "normal normal 400 14px/16px PoppinsMedium",
    paddingRight: 0,
    ["@media (max-width:1025px)"]: {
      font: "normal normal 400 12px/14px PoppinsMedium",
    },
    ["@media (min-width:1026px) and (max-width:1282px)"]: {
      font: "normal normal 400 14px/16px PoppinsMedium",
    },
    ["@media (min-width:1283px) and (max-width:1366px)"]: {
      font: "normal normal 400 16px/18px PoppinsMedium",
    },
    ["@media (min-width:1367px) and (max-width:1442px)"]: {
      font: "normal normal 400 20px/22px PoppinsMedium",
    },
    ["@media (min-width:1443px)"]: {
      font: "normal normal 400 24px/28px PoppinsMedium",
      marginBottom: "30px",
    },
  },
  verticalHorizontalCenter: {
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
  },
  alignFlexEnd: {
    display: "flex",
    alignItems: "flex-end",
  },
  tableCaption: {
    color: "#BE2431",
    marginBottom: 0,
    font: "normal normal 500 12px/20px PoppinsSemiBold",
    ["@media (min-width:1283px) and (max-width:1366px)"]: {
      font: "normal normal 500 14px/20px PoppinsSemiBold",
    },
    ["@media (min-width:1367px) and (max-width:1442px)"]: {
      font: "normal normal 500 16px/22px PoppinsSemiBold",
    },
    ["@media (min-width:1443px)"]: {
      font: "normal normal 500 18px/24px PoppinsSemiBold",
    },
  },
  sectionSubTitleBorder: {
    borderBottom: "1px solid #B7AEAE",
    paddingBottom: "16px",
    marginBottom: 4,
  },
  sectionSubTitleBorder2: {
    borderTop: "1px solid #B7AEAE",
  },
  titleBorderTop: {
    borderTop: "1px solid #B7AEAE",
    marginTop: "3px",
    paddingTop: "25px",
  },

  sectionBottomBorder: {
    borderBottom: "1px solid #BE2431",
  },
  thumbnailImg: {
    width: "26px",
    height: "26px",
  },
  thumbnailText: {
    font: "normal normal 400 10px/14px PoppinsMedium",
    color: "#626262",
    padding: "0 14px 0 0px",
    marginLeft: "10px",
    textAlign: "left",
    ["@media (max-width:1282px)"]: {
      font: "normal normal 500 10px/14px PoppinsMedium",
    },
    ["@media (min-width:1283px) and (max-width:1366px)"]: {
      font: "normal normal 400 10px/14px PoppinsMedium",
    },
    ["@media (min-width:1367px) and (max-width:1442px)"]: {
      font: "normal normal 400 12px/16px PoppinsMedium",
    },
    ["@media (min-width:1443px)"]: {
      font: "normal normal 400 14px/20px PoppinsMedium",
    },
  },
  tipContainer: {
    position: "absolute",
    top: "0",
    right: "25px",
    color: "#6FBC44",
    width: "100px",
    height: "auto",
    font: "normal normal 500 12px/16px PoppinsSemiBold",
    zIndex: 999,
    ["@media (max-width:1282px)"]: {},
    ["@media (min-width:1283px) and (max-width:1366px)"]: {
      width: "100px",
      font: "normal normal 500 14px/18px PoppinsSemiBold",
    },
    ["@media (min-width:1367px) and (max-width:1442px)"]: {
      font: "normal normal 500 16px/22px PoppinsSemiBold",
      top: "10px",
      width: "125px",
    },
    ["@media (min-width:1443px)"]: {
      font: "normal normal 500 16px/22px PoppinsSemiBold",
      top: "30px",
      width: "125px",
      right: "50px",
    },
  },
  tipArrowIcon: {
    ["@media (min-width:1443px)"]: {
      width: "50px",
    },
  },
  keyContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
  },
}));

export default function Screen4(props) {
  const classes = useStyles();

  const [ppNumber, setPpNumber] = useState(0);
  const [ppType, setPpType] = useState(0);

  const TableHeaderData = [
    {
      id: 1,
      type: "ht",
      class: "ht-icon",
      title: "Learn More",
      text: `${props.data.htColumn1}`,
    },
  ];

  const openSelectedOutcomeDescription = (type, ppNumber) => (event) => {
    document.getElementsByClassName(
      "animated-drawer-container"
    )[0].style.display = "block";
    document.getElementById("header").style.position = "static";
    document.getElementById("tipContainer").style.display = "none";
    document.getElementById("progressBar").style.display = "none";

    setPpNumber(ppNumber);
    setPpType(type);
  };

  const closeSelectedOutcomeDescription = (type) => {
    document.getElementsByClassName(
      "animated-drawer-container"
    )[0].style.display = "none";
    document.getElementById("header").style.position = "relative";
    document.getElementById("tipContainer").style.display = "block";
    document.getElementById("progressBar").style.display = "block";
  };

  const getIconTitle = (data) => {
    if (data === "improvement") {
      return "Scope for Improvement";
    } else if (data === "thumbsup") {
      return "Well Done";
    } else {
      return "Biases & Motivational factors were not tested here";
    }
  };

  const getHtMatrixIcon = (data) => {
    if (data === "Scope for Improvement") {
      return "Scope for Improvement";
    } else if (data === "Well done") {
      return "Well Done";
    } else {
      return "Practice Required";
    }
  };
  const getCaptionColor = (data) => {
    console.log("data ======>", data);
    if (data === "Scope for Improvement") {
      return "red";
    } else if (data === "Well done") {
      return "green";
    } else {
      return "blue";
    }
  };

  return (
    <div className="pos-rel">
      <div id="tipContainer" className={classes.tipContainer}>
        <p className="greenColor">Tip: click on each icon to learn more</p>
        <img
          className={classes.tipArrowIcon}
          src={tipArrowIcon}
          alt="tipArrowIcon"
        />
      </div>
      <Grid
        container
        component="main"
        className={`${classes.reportContentContainer} ${classes.verticalHorizontalCenter}`}
      >
        <Grid item xs={12} sm={4} md={4}>
          <h3 className={classes.sectionTitle}>Human Thinking</h3>
          {props.data.category_id === 1 ? (
            <React.Fragment>
              <p className={classes.paragraph}>
                Human Thinking is one’s mindset for creating social networks and
                trust relevant in a start-up and self- employment scenario.
              </p>
              {/* <p className={classes.paragraph}>
                This reflects your mindset for creating social networks and
                trust relevant in a start-up and a self-employment scenario.
              </p> */}
              <p className={classes.paragraph}>
                Entrepreneurial potential is strongly influenced by one's
                biases, motivational factors and consistency in thinking. This
                table demonstrates yours for creating social networks and trust.
              </p>
              {/* <p className={classes.paragraph}>
                One’s entrepreneurial potential is strongly influenced by one’s
                biases, motivational factors and consistency in thinking.
              </p> */}
              {/* <p
                className={classes.paragraph}
                style={{
                  color: "#151515",
                  fontFamily: "PoppinsSemiBold",
                  fontWeight: "500",
                }}
              >
                This mind map demonstrates them for creating professional
                relation with trust
              </p> */}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <p className={classes.paragraph}>
                {props.data.category_id === 4 ? (
                  <React.Fragment>
                    Human Thinking is one’s mindset for creating social networks
                    and trust relevant for an employability in a commercial
                    career.
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    Human Thinking is one’s mindset for creating social networks
                    and trust relevant in a commercial role.
                  </React.Fragment>
                )}
              </p>
              <p className={classes.paragraph}>
                {props.data.category_id === 4 ? (
                  <React.Fragment>
                    Entrepreneurial potential is strongly influenced by one's
                    biases, motivational factors and consistency in thinking.
                    This table demonstrates yours for creating social networks
                    and trust.
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    Entrepreneurial potential is strongly influenced by one's
                    biases, motivational factors and consistency in thinking.
                    This table demonstrates yours for creating social networks
                    and trust.
                  </React.Fragment>
                )}
              </p>
            </React.Fragment>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          md={8}
          component={Paper}
          elevation={6}
          className={classes.rightSideContentContainer}
        >
          <Grid container className={classes.rightSideContainer}>
            <Grid item xs={1} sm={1} md={1}></Grid>
            <Grid item xs={5} sm={5} md={5}>
              {/* <div> */}
              <h4 className={`${classes.sectionSubTitle}`} 
                onClick={openSelectedOutcomeDescription("ht", 4)}>
                {props.data.category_id === 1 ? (
                  <span>
                    SOCIAL <br /> BEHAVIOUR
                  </span>
                ) : (
                  <span>
                    SOCIAL <br /> BEHAVIOUR
                  </span>
                )}
              </h4>
              <img
                // src={getHtMatrixIcon(props.data.hs1MatrixCaption)}
                src={
                  require(`./../../assets/images/report/score/${props.data.hs1MatrixCaption}.svg`)
                    .default
                }
                alt=""
                class="ht-icon"
                onClick={openSelectedOutcomeDescription("ht", 4)}
                title="Learn More"
              />
              <p
                className={`${classes.captionText} ${getCaptionColor(
                  props.data.hs1MatrixCaption
                )}`}
              >
                {props.data.hs1MatrixCaption}
              </p>
              {/* </div> */}
            </Grid>
            <Grid item xs={1} sm={1} md={1} style={{ position: "relative" }}>
              <div className="verticalLine"></div>
              <div className="verticalLine2"></div>
            </Grid>
            <Grid item xs={5} sm={5} md={5}>
              <div>
                <h4 className={`${classes.sectionSubTitle}`} 
                  onClick={openSelectedOutcomeDescription("ht", 1)}>
                  {props.data.category_id === 1 ? (
                    <span>
                      TRUSTFUL PROFESSIONAL <br /> RELATIONSHIP
                    </span>
                  ) : (
                    <span>
                      TRUSTFUL PROFESSIONAL <br /> RELATIONSHIP
                    </span>
                  )}
                </h4>
                <img
                  src={
                    require(`./../../assets/images/report/score/${props.data.hs3MatrixCaption}.svg`)
                      .default
                  }
                  alt=""
                  class="ht-icon"
                  onClick={openSelectedOutcomeDescription("ht", 1)}
                  title="Learn More"
                />
                <p
                  className={`${classes.captionText} ${getCaptionColor(
                    props.data.hs3MatrixCaption
                  )}`}
                >
                  {props.data.hs3MatrixCaption}
                </p>
              </div>
            </Grid>
            <Grid item xs={1} sm={1} md={1}></Grid>
            <Grid item xs={5} sm={5} md={5}>
              <div className={`${classes.sectionSubTitleBorder2}`}>
                <h4
                  className={`${classes.sectionSubTitle} ${classes.titleBorderTop}`} 
                  onClick={openSelectedOutcomeDescription("ht", 3)}
                >
                  {props.data.category_id === 1 ? (
                    <span>
                      Founder <br /> Mindset
                    </span>
                  ) : props.data.category_id === 4 ? (
                    <span>
                      Work <br /> Attitude
                    </span>
                  ) : (
                    <span>
                      Employee <br /> Attitude
                    </span>
                  )}
                </h4>
                <img
                  src={
                    require(`./../../assets/images/report/score/${props.data.hs2MatrixCaption}.svg`)
                      .default
                  }
                  alt=""
                  class="ht-icon"
                  onClick={openSelectedOutcomeDescription("ht", 3)}
                  title="Learn More"
                />
                <p
                  className={`${classes.captionText} ${getCaptionColor(
                    props.data.hs2MatrixCaption
                  )}`}
                >
                  {props.data.hs2MatrixCaption}
                </p>
              </div>
            </Grid>
            <Grid item xs={1} sm={1} md={1} style={{ position: "relative" }}>
              <img src={etqCube} alt="" class="ht-icon etq-cube" />

              <div className="verticalLine bt-0"></div>
              <div className="verticalLine2 bt-0"></div>
            </Grid>
            <Grid item xs={5} sm={5} md={5}>
              <div className={`${classes.sectionSubTitleBorder2}`}>
                <h4
                  className={`${classes.sectionSubTitle}  ${classes.titleBorderTop}`} 
                  onClick={openSelectedOutcomeDescription("ht", 2)}
                >
                  {props.data.category_id === 1 ? (
                    <span>
                      DIVERSITY <br /> OF NETWORKS
                    </span>
                  ) : (
                    <span>
                      DIVERSITY <br /> OF NETWORKS
                    </span>
                  )}
                </h4>
                <img
                  src={
                    require(`./../../assets/images/report/score/${props.data.hs4MatrixCaption}.svg`)
                      .default
                  }
                  alt=""
                  class="ht-icon"
                  onClick={openSelectedOutcomeDescription("ht", 2)}
                  title="Learn More"
                />
                <p
                  className={`${classes.captionText} ${getCaptionColor(
                    props.data.hs4MatrixCaption
                  )}`}
                >
                  {props.data.hs4MatrixCaption}
                </p>
              </div>
            </Grid>
            <Grid item xs={2} sm={2} md={2}></Grid>
            <Grid item xs={10} sm={10} md={10}>
              <p
                className={classes.paragraph}
                style={{
                  color: "#000",
                  fontFamily: "PoppinsMedium",
                  fontWeight: "600",
                  textAlign: "left",
                }}
              >
                Your biases, motivational factors and mindset were evaluated.
              </p>
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid
          item
          xs={12}
          sm={9}
          md={9}
          component={Paper}
          elevation={9}
          className={classes.rightSideContentContainer}
        >
          <Grid container className={classes.rightSideContainer}>
            <Grid item xs={1}></Grid>
            <Grid item xs className={classes.sectionBottomBorder}>
              <img
                src={trust}
                alt=""
                class="ht-icon"
                onClick={openSelectedOutcomeDescription("ht", 1)}
                title="Learn More"
              />
              <h4
                className={`${classes.sectionSubTitle} ${classes.sectionSubTitleBorder}`}
              >
                {props.data.category_id === 1 ? (
                  <span>
                    Trustful Professional <br /> Relationship
                  </span>
                ) : (
                  <span>
                    Trustful Professional <br /> Relationship
                  </span>
                )}
              </h4>
            </Grid>
            <Grid item xs className={classes.sectionBottomBorder}>
              <img
                src={smartNetworks}
                alt=""
                class="ht-icon"
                onClick={openSelectedOutcomeDescription("ht", 2)}
                title="Learn More"
              />
              <h4
                className={`${classes.sectionSubTitle} ${classes.sectionSubTitleBorder}`}
              >
                {props.data.category_id === 1 ? (
                  <span>
                    Diversity of <br /> Network
                  </span>
                ) : (
                  <span>
                    Diversity of <br /> Network
                  </span>
                )}
              </h4>
            </Grid>
            <Grid item xs className={classes.sectionBottomBorder}>
              <img
                src={workMeaning}
                alt=""
                class="ht-icon"
                onClick={openSelectedOutcomeDescription("ht", 3)}
                title="Learn More"
              />
              <h4
                className={`${classes.sectionSubTitle} ${classes.sectionSubTitleBorder}`}
              >
                {props.data.category_id === 1 ? (
                  <span>
                    Founder <br /> Mindset
                  </span>
                ) : props.data.category_id === 4 ? (
                  <span>
                    Work <br /> Attitude
                  </span>
                ) : (
                  <span>
                    Employee <br /> Attitude
                  </span>
                )}
              </h4>
            </Grid>
            <Grid item xs className={classes.sectionBottomBorder}>
              <img
                src={socialBehaviour}
                alt=""
                class="ht-icon"
                onClick={openSelectedOutcomeDescription("ht", 4)}
                title="Learn More"
              />
              <h4
                className={`${classes.sectionSubTitle} ${classes.sectionSubTitleBorder}`}
              >
                {props.data.category_id === 1 ? (
                  <span>
                    Social <br /> Behaviour
                  </span>
                ) : (
                  <span>
                    Social <br /> Behaviour
                  </span>
                )}
                <br />
              </h4>
            </Grid>
          </Grid>

          <div>
            <Grid container style={{ borderBottom: "1px solid #BE2431" }}>
              <Grid item xs={1} className={classes.alignFlexEnd}>
                <p className={classes.tableCaption}>MINDSET</p>
              </Grid>
              <Grid item xs>
                <img
                  src={
                    require(`./../../assets/images/report/${props.data.humanThinkingMindsetTrustfulProfessionalRelationships}.svg`)
                      .default
                  }
                  alt=""
                  className={
                    "icon-" +
                    props.data
                      .humanThinkingMindsetTrustfulProfessionalRelationships
                  }
                  title={getIconTitle(
                    props.data
                      .humanThinkingMindsetTrustfulProfessionalRelationships
                  )}
                />
              </Grid>
              <Grid item xs>
                <img
                  src={
                    require(`./../../assets/images/report/${props.data.humanThinkingMindsetBuildingSmartNetworks}.svg`)
                      .default
                  }
                  alt=""
                  className={
                    "icon-" +
                    props.data.humanThinkingMindsetBuildingSmartNetworks
                  }
                  title={getIconTitle(
                    props.data.humanThinkingMindsetBuildingSmartNetworks
                  )}
                />
              </Grid>
              <Grid item xs>
                <img
                  src={
                    require(`./../../assets/images/report/${props.data.humanThinkingMindsetWorkAndMenaing}.svg`)
                      .default
                  }
                  alt=""
                  className={
                    "icon-" + props.data.humanThinkingMindsetWorkAndMenaing
                  }
                  title={getIconTitle(
                    props.data.humanThinkingMindsetWorkAndMenaing
                  )}
                />
              </Grid>
              <Grid item xs>
                <img
                  src={
                    require(`./../../assets/images/report/${props.data.humanThinkingMindsetSocialBehaviour}.svg`)
                      .default
                  }
                  alt=""
                  className={
                    "icon-" + props.data.humanThinkingMindsetSocialBehaviour
                  }
                  title={getIconTitle(
                    props.data.humanThinkingMindsetSocialBehaviour
                  )}
                />
              </Grid>
            </Grid>
          </div>

          <div>
            <Grid container style={{ borderBottom: "1px solid #BE2431" }}>
              <Grid item xs={1} className={classes.alignFlexEnd}>
                <p className={classes.tableCaption}>BIASES</p>
              </Grid>
              <Grid item xs>
                <img
                  src={
                    require(`./../../assets/images/report/not-applicable.svg`)
                      .default
                  }
                  alt=""
                  className={"icon-notApplicable"}
                  title={getIconTitle("notApplicable")}
                />
              </Grid>
              <Grid item xs>
                <img
                  src={
                    require(`./../../assets/images/report/${props.data.humanThinkingBiasesSmartNetworks}.svg`)
                      .default
                  }
                  alt=""
                  className={
                    "icon-" + props.data.humanThinkingBiasesSmartNetworks
                  }
                  title={getIconTitle(
                    props.data.humanThinkingBiasesSmartNetworks
                  )}
                />
              </Grid>
              <Grid item xs>
                <img
                  src={
                    require(`./../../assets/images/report/${props.data.humanThinkingBiasesWorkandMeaning}.svg`)
                      .default
                  }
                  alt=""
                  className={
                    "icon-" + props.data.humanThinkingBiasesWorkandMeaning
                  }
                  title={getIconTitle(
                    props.data.humanThinkingBiasesWorkandMeaning
                  )}
                />
              </Grid>
              <Grid item xs>
                <img
                  src={
                    require(`./../../assets/images/report/not-applicable.svg`)
                      .default
                  }
                  alt=""
                  className={"icon-notApplicable"}
                  title={getIconTitle("notApplicable")}
                />
              </Grid>
            </Grid>
          </div>

          <div>
            <Grid container style={{ borderBottom: "1px solid #BE2431" }}>
              <Grid item xs={1} className={classes.alignFlexEnd}>
                <p className={classes.tableCaption}>MOTIVATION</p>
              </Grid>
              <Grid item xs>
                <img
                  src={
                    require(`./../../assets/images/report/${props.data.humanThinkingMotivationProfessionalRelationships}.svg`)
                      .default
                  }
                  alt=""
                  className={
                    "icon-" +
                    props.data.humanThinkingMotivationProfessionalRelationships
                  }
                  title={getIconTitle(
                    props.data.humanThinkingMotivationProfessionalRelationships
                  )}
                />
              </Grid>
              <Grid item xs>
                <img
                  src={
                    require(`./../../assets/images/report/not-applicable.svg`)
                      .default
                  }
                  alt=""
                  className={"icon-notApplicable"}
                  title={getIconTitle("notApplicable")}
                />
              </Grid>
              <Grid item xs>
                <img
                  src={
                    require(`./../../assets/images/report/${props.data.humanThinkingMotivationWorkMeaning}.svg`)
                      .default
                  }
                  alt=""
                  className={
                    "icon-" + props.data.humanThinkingMotivationWorkMeaning
                  }
                  title={getIconTitle(
                    props.data.humanThinkingMotivationWorkMeaning
                  )}
                />
              </Grid>
              <Grid item xs>
                <img
                  src={
                    require(`./../../assets/images/report/${props.data.humanThinkingMotivationSocialBehaviour}.svg`)
                      .default
                  }
                  alt=""
                  className={
                    "icon-" + props.data.humanThinkingMotivationSocialBehaviour
                  }
                  title={getIconTitle(
                    props.data.humanThinkingMotivationSocialBehaviour
                  )}
                />
              </Grid>
            </Grid>
          </div>

          <div>
            <Grid container>
              <Grid item xs={1}>
                <p style={{ color: "rgb(190, 36, 49)" }}></p>
              </Grid>
              <Grid item xs className={classes.keyContainer}>
                <p className={classes.thumbnailText} style={{ float: "right" }}>
                  KEY:
                </p>
              </Grid>
              <Grid item xs className={classes.verticalHorizontalCenter}>
                <img
                  className={classes.thumbnailImg}
                  src={notApplicable}
                  alt=""
                />
                <p className={classes.thumbnailText}>
                  Biases & Motivational <br /> factors were not tested here
                </p>
              </Grid>
              <Grid item xs className={classes.verticalHorizontalCenter}>
                <img
                  className={classes.thumbnailImg}
                  src={improvement}
                  alt=""
                />
                <p className={classes.thumbnailText}>
                  Scope <br /> for Improvement
                </p>
              </Grid>
              <Grid item xs className={classes.verticalHorizontalCenter}>
                <img className={classes.thumbnailImg} src={thumbsUp} alt="" />
                <p className={classes.thumbnailText}>
                  Well <br /> Done
                </p>
              </Grid>
            </Grid>
          </div>
        </Grid> */}

        <div class="animated-drawer-container">
          {props.data.pointsToPonder &&
            props.data.pointsToPonder.map((score) => {
              return ppNumber === score.id ? (
                <AnimatedDrawer
                  animatedDrawerType={closeSelectedOutcomeDescription}
                  ppType={ppNumber}
                  data={score}
                  type={ppType}
                ></AnimatedDrawer>
              ) : null;
            })}
        </div>
      </Grid>
    </div>
  );
}
